import '@angular/localize/init';

export enum QueryEngineReportType {
    Device = 'device',
    Install = 'install',
    Entity = 'entity',
    DeviceMeterHistory = 'deviceMeterHistory',
    SerialNumberMeterHistory = 'serialNumberMeterHistory',
    CurrentMeter = 'currentMeter',
    VolumeAnalysis = 'volumeAnalysis',
    BillingPeriod = 'billingPeriod',
    EstimatedDepletionOnDemand = 'estimatedDepletionOnDemand',
    MonthlyDeviceVolume = 'monthlyDeviceVolume',
    Supplies = 'supplies',
    BillableDevices = 'billableDevices',
    Alerts = 'alerts'
}

export namespace QueryEngineReportType {
    export const allReportTypes = [
        QueryEngineReportType.Device,
        QueryEngineReportType.Install,
        QueryEngineReportType.Entity,
        QueryEngineReportType.DeviceMeterHistory,
        QueryEngineReportType.SerialNumberMeterHistory,
        QueryEngineReportType.CurrentMeter,
        QueryEngineReportType.VolumeAnalysis,
        QueryEngineReportType.BillingPeriod,
        QueryEngineReportType.EstimatedDepletionOnDemand,
        QueryEngineReportType.MonthlyDeviceVolume,
        QueryEngineReportType.Supplies,
        QueryEngineReportType.BillableDevices,
        QueryEngineReportType.Alerts
    ];
    // selectableReportTypes excludes QueryEngineReportType.DeviceMeterHistory because the query engine
    // interface does not currently support selecting a specific device, rather you run device meter history
    // reports from the device page.
    export const selectableReportTypes = allReportTypes.filter(
        t =>
            t !== QueryEngineReportType.DeviceMeterHistory &&
            t !== QueryEngineReportType.SerialNumberMeterHistory
    );
    export function fromString(str: string): QueryEngineReportType {
        switch (str) {
            case QueryEngineReportType.Device:
                return QueryEngineReportType.Device;
            case QueryEngineReportType.Install:
                return QueryEngineReportType.Install;
            case QueryEngineReportType.Entity:
                return QueryEngineReportType.Entity;
            case QueryEngineReportType.DeviceMeterHistory:
                return QueryEngineReportType.DeviceMeterHistory;
            case QueryEngineReportType.SerialNumberMeterHistory:
                return QueryEngineReportType.SerialNumberMeterHistory;
            case QueryEngineReportType.CurrentMeter:
                return QueryEngineReportType.CurrentMeter;
            case QueryEngineReportType.VolumeAnalysis:
                return QueryEngineReportType.VolumeAnalysis;
            case QueryEngineReportType.BillingPeriod:
                return QueryEngineReportType.BillingPeriod;
            case QueryEngineReportType.EstimatedDepletionOnDemand:
                return QueryEngineReportType.EstimatedDepletionOnDemand;
            case QueryEngineReportType.MonthlyDeviceVolume:
                return QueryEngineReportType.MonthlyDeviceVolume;
            case QueryEngineReportType.Supplies:
                return QueryEngineReportType.Supplies;
            case QueryEngineReportType.BillableDevices:
                return QueryEngineReportType.BillableDevices;
            case QueryEngineReportType.Alerts:
                return QueryEngineReportType.Alerts;
            default:
                throw new Error(`unsupported report type ${str}`);
        }
    }
    export function toDescription(type: QueryEngineReportType): string {
        switch (type) {
            case QueryEngineReportType.Device:
                return 'Allows for querying device information such as serial number, asset ID, make, model, etc.';
            case QueryEngineReportType.Install:
                return 'Allows for querying install information such as hostname, operation system, architecture, IP address, etc.';
            case QueryEngineReportType.Entity:
                return 'Allows for querying entity information.';
            case QueryEngineReportType.DeviceMeterHistory:
                return 'Allows for querying historical meter data about a specific device.';
            case QueryEngineReportType.SerialNumberMeterHistory:
                return 'Allows for querying historical meter data about a specific serial number which may or may not represent the same device.';
            case QueryEngineReportType.CurrentMeter:
                return 'Allows for querying any information contained in any current meter read of any device including page counts, supplies, and more.';
            case QueryEngineReportType.VolumeAnalysis:
                return 'Contains the first and last meter reads for the specified date range.';
            case QueryEngineReportType.BillingPeriod:
                return 'Contains the first and last meter reads for the billing period of each device.';
            case QueryEngineReportType.EstimatedDepletionOnDemand:
                return 'Calculates the depletion date of the black, cyan, magenta and yellow toners.';
            case QueryEngineReportType.MonthlyDeviceVolume:
                return 'Calculates the monthly volumes of each device at an entity over the last 12 months.';
            case QueryEngineReportType.Supplies:
                return 'Allows for querying all the currently installed and historical supplies for every device.';
            case QueryEngineReportType.BillableDevices:
                return 'Returns all the devices that were billed by Print Tracker for the specified date range.';
            case QueryEngineReportType.Alerts:
                return 'Returns all the alerts that were generated by Print Tracker for the specified date range.';
            default:
                return '';
        }
    }
    export function toPrimaryTableName(type: QueryEngineReportType): string {
        switch (type) {
            case QueryEngineReportType.Install:
                return 'installs';
            case QueryEngineReportType.Device:
            case QueryEngineReportType.MonthlyDeviceVolume:
                return 'devices';
            case QueryEngineReportType.DeviceMeterHistory:
            case QueryEngineReportType.SerialNumberMeterHistory:
            case QueryEngineReportType.CurrentMeter:
            case QueryEngineReportType.VolumeAnalysis:
            case QueryEngineReportType.BillingPeriod:
                return 'meters';
            case QueryEngineReportType.EstimatedDepletionOnDemand:
                return 'device_estimated_depletion_on_demand';
            case QueryEngineReportType.Supplies:
                return 'supplies';
            case QueryEngineReportType.BillableDevices:
                return 'billable_devices';
            case QueryEngineReportType.Entity:
                return 'entities';
            case QueryEngineReportType.Alerts:
                return 'alerts';
            default:
                return '';
        }
    }
    export function toDisplayName(type: QueryEngineReportType): string {
        switch (type) {
            case QueryEngineReportType.Device:
                return $localize`Device`;
            case QueryEngineReportType.Install:
                return $localize`Install`;
            case QueryEngineReportType.Entity:
                return $localize`Entity`;
            case QueryEngineReportType.DeviceMeterHistory:
                return $localize`Device Meter History`;
            case QueryEngineReportType.SerialNumberMeterHistory:
                return $localize`Serial Number Meter History`;
            case QueryEngineReportType.CurrentMeter:
                return $localize`Current Meter`;
            case QueryEngineReportType.VolumeAnalysis:
                return $localize`Volume Analysis`;
            case QueryEngineReportType.BillingPeriod:
                return $localize`Billing Period`;
            case QueryEngineReportType.EstimatedDepletionOnDemand:
                return $localize`Estimated Depletion (On-demand)`;
            case QueryEngineReportType.MonthlyDeviceVolume:
                return $localize`Monthly Device Volume`;
            case QueryEngineReportType.Supplies:
                return $localize`Supplies`;
            case QueryEngineReportType.BillableDevices:
                return $localize`Billable Devices`;
            case QueryEngineReportType.Alerts:
                return $localize`Alerts`;
            default:
                return '';
        }
    }
    export function requiresNoPayload(type: QueryEngineReportType): boolean {
        return (
            type === QueryEngineReportType.Device ||
            type === QueryEngineReportType.Install ||
            type === QueryEngineReportType.CurrentMeter ||
            type === QueryEngineReportType.MonthlyDeviceVolume
        );
    }
    export function requiresDateRange(type: QueryEngineReportType): boolean {
        return (
            type === QueryEngineReportType.VolumeAnalysis ||
            type === QueryEngineReportType.DeviceMeterHistory ||
            type === QueryEngineReportType.SerialNumberMeterHistory ||
            type === QueryEngineReportType.Supplies ||
            type === QueryEngineReportType.Alerts
        );
    }
    export function requiresMonthlyDateRange(type: QueryEngineReportType): boolean {
        return type === QueryEngineReportType.BillableDevices;
    }
    export function requiresBillingDate(type: QueryEngineReportType): boolean {
        return type === QueryEngineReportType.BillingPeriod;
    }

    export function requiresIncludeChildren(type: QueryEngineReportType): boolean {
        return (
            type !== QueryEngineReportType.Entity &&
            type !== QueryEngineReportType.EstimatedDepletionOnDemand
        );
    }

    export function isBeta(type: QueryEngineReportType): boolean {
        return type === QueryEngineReportType.Alerts;
    }

    export function toDefaultQuery(type: QueryEngineReportType): string {
        switch (type) {
            case QueryEngineReportType.Device:
                return `SELECT
    entity_name as 'Entity',
    make as Make,
    model as Model,
    serial_number as 'Serial Number',
    asset_id as 'Asset ID',
    ip_address as 'IP Address',
    mac_address as 'Mac Address',
    DATETIME(latest_meter_timestamp) as 'Latest Meter Timestamp',
    managed as 'Managed'
FROM devices
WHERE managed = true
ORDER BY latest_meter_timestamp desc`;
            case QueryEngineReportType.Install:
                return `SELECT
    entity_name as 'Entity Name',
    hostname as 'Hostname',
    version as 'Version',
    os as 'Operating System',
    antivirus as 'Antivirus',
    is_laptop as 'Laptop',
    ip_address as 'IP Address',
    DATETIME(created_timestamp) as 'Created'
FROM installs
ORDER BY created_timestamp DESC`;
            case QueryEngineReportType.CurrentMeter:
                return `SELECT
    entity_name as 'Entity',
    make as Make,
    model as Model,
    serial_number as 'Serial Number',
    asset_id as 'Asset ID',
    ip_address as 'IP Address',
    DATETIME(latest_meter_timestamp) as 'Latest Meter Timestamp',
    pageCounts_default_total as 'Total',
    pageCounts_default_totalBlack as 'Total Black',
    pageCounts_default_totalColor as 'Total Color'
FROM meters
WHERE managed = true
ORDER BY latest_meter_timestamp desc`;
            case QueryEngineReportType.VolumeAnalysis:
                return `SELECT
    entity_name as 'Entity',
    make as 'Make',
    model as 'Model',
    serial_number as 'Serial Number',
    asset_id as 'Asset ID',
    ip_address as 'IP Address',
    mac_address as 'Mac Address',
    location as 'Location',
    DATETIME(first_meter_timestamp) as 'First Timestamp',
    DATETIME(last_meter_timestamp) as 'Last Timestamp',
    ROUND(JULIANDAY(last_meter_timestamp) - JULIANDAY(first_meter_timestamp), 2) as 'Days Between Meters',
    first_pageCounts_default_total as 'First Total',
    first_pageCounts_default_totalBlack as 'First Total Black',
    first_pageCounts_default_totalColor as 'First Total Color',
    first_pageCounts_default_totalPrints as 'First Total Prints',
    first_pageCounts_default_totalCopies as 'First Total Copies',
    first_pageCounts_default_totalFaxes as 'First Total Faxes',
    first_pageCounts_default_totalScans as 'First Total Scans',
    first_pageCounts_default_tier1Total as 'First Tier 1 Total',
    first_pageCounts_default_tier2Total as 'First Tier 2 Total',
    first_pageCounts_default_tier3Total as 'First Tier 3 Total',
    last_pageCounts_default_total as 'Last Total',
    last_pageCounts_default_totalBlack as 'Last Total Black',
    last_pageCounts_default_totalColor as 'Last Total Color',
    last_pageCounts_default_totalPrints as 'Last Total Prints',
    last_pageCounts_default_totalCopies as 'Last Total Copies',
    last_pageCounts_default_totalFaxes as 'Last Total Faxes',
    last_pageCounts_default_totalScans as 'Last Total Scans',
    last_pageCounts_default_tier1Total as 'Last Tier 1 Total',
    last_pageCounts_default_tier2Total as 'Last Tier 2 Total',
    last_pageCounts_default_tier3Total as 'Last Tier 3 Total',
    last_pageCounts_default_total - first_pageCounts_default_total as 'Total Volume',
    last_pageCounts_default_totalBlack - first_pageCounts_default_totalBlack as 'Total Black Volume',
    last_pageCounts_default_totalColor - first_pageCounts_default_totalColor as 'Total Color Volume',
    last_pageCounts_default_totalPrints - first_pageCounts_default_totalPrints as 'Total Prints Volume',
    last_pageCounts_default_totalCopies - first_pageCounts_default_totalCopies as 'Total Copies Volume',
    last_pageCounts_default_totalFaxes - first_pageCounts_default_totalFaxes as 'Total Faxes Volume',
    last_pageCounts_default_totalScans - first_pageCounts_default_totalScans as 'Total Scans Volume',
    last_pageCounts_default_tier1Total - first_pageCounts_default_tier1Total as 'Tier 1 Total Volume',
    last_pageCounts_default_tier2Total - first_pageCounts_default_tier2Total as 'Tier 2 Total Volume',
    last_pageCounts_default_tier3Total - first_pageCounts_default_tier3Total as 'Tier 3 Total Volume'
FROM meters`;
            case QueryEngineReportType.BillingPeriod:
                return `SELECT
    entity_name as 'Entity',
    make as 'Make',
    model as 'Model',
    serial_number as 'Serial Number',
    asset_id as 'Asset ID',
    ip_address as 'IP Address',
    mac_address as 'Mac Address',
    location as 'Location',
    DATETIME(first_meter_timestamp) as 'First Timestamp',
    DATETIME(last_meter_timestamp) as 'Last Timestamp',
    ROUND(JULIANDAY(last_meter_timestamp) - JULIANDAY(first_meter_timestamp), 2) as 'Days Between Meters',
    first_pageCounts_default_total as 'First Total',
    first_pageCounts_default_totalBlack as 'First Total Black',
    first_pageCounts_default_totalColor as 'First Total Color',
    first_pageCounts_default_totalPrints as 'First Total Prints',
    first_pageCounts_default_totalCopies as 'First Total Copies',
    first_pageCounts_default_totalFaxes as 'First Total Faxes',
    first_pageCounts_default_totalScans as 'First Total Scans',
    first_pageCounts_default_tier1Total as 'First Tier 1 Total',
    first_pageCounts_default_tier2Total as 'First Tier 2 Total',
    first_pageCounts_default_tier3Total as 'First Tier 3 Total',
    last_pageCounts_default_total as 'Last Total',
    last_pageCounts_default_totalBlack as 'Last Total Black',
    last_pageCounts_default_totalColor as 'Last Total Color',
    last_pageCounts_default_totalPrints as 'Last Total Prints',
    last_pageCounts_default_totalCopies as 'Last Total Copies',
    last_pageCounts_default_totalFaxes as 'Last Total Faxes',
    last_pageCounts_default_totalScans as 'Last Total Scans',
    last_pageCounts_default_tier1Total as 'Last Tier 1 Total',
    last_pageCounts_default_tier2Total as 'Last Tier 2 Total',
    last_pageCounts_default_tier3Total as 'Last Tier 3 Total',
    last_pageCounts_default_total - first_pageCounts_default_total as 'Total Volume',
    last_pageCounts_default_totalBlack - first_pageCounts_default_totalBlack as 'Total Black Volume',
    last_pageCounts_default_totalColor - first_pageCounts_default_totalColor as 'Total Color Volume',
    last_pageCounts_default_totalPrints - first_pageCounts_default_totalPrints as 'Total Prints Volume',
    last_pageCounts_default_totalCopies - first_pageCounts_default_totalCopies as 'Total Copies Volume',
    last_pageCounts_default_totalFaxes - first_pageCounts_default_totalFaxes as 'Total Faxes Volume',
    last_pageCounts_default_totalScans - first_pageCounts_default_totalScans as 'Total Scans Volume',
    last_pageCounts_default_tier1Total - first_pageCounts_default_tier1Total as 'Tier 1 Total Volume',
    last_pageCounts_default_tier2Total - first_pageCounts_default_tier2Total as 'Tier 2 Total Volume',
    last_pageCounts_default_tier3Total - first_pageCounts_default_tier3Total as 'Tier 3 Total Volume'
FROM meters`;
            case QueryEngineReportType.DeviceMeterHistory:
                return `SELECT
    DATETIME(timestamp) as 'Timestamp',
    meter_ip_address as 'IP Address',
    pageCounts_default_total as 'Total',
    pageCounts_default_totalBlack as 'Total Black',
    pageCounts_default_totalColor as 'Total Color',
    pageCounts_default_totalCopies as 'Total Copies',
    pageCounts_default_totalPrints as 'Total Prints',
    pageCounts_default_totalFaxes as 'Total Faxes',
    pageCounts_default_totalScans as 'Total Scans',
    pageCounts_default_tier1Total as 'Tier 1 Total',
    pageCounts_default_tier2Total as 'Tier 2 Total',
    pageCounts_default_tier3Total as 'Tier 3 Total',
    COALESCE(supplies_blackToner_pctRemaining, supplies_blackInk_pctRemaining) as 'Black',
    COALESCE(supplies_cyanToner_pctRemaining, supplies_cyanInk_pctRemaining) as 'Cyan',
    COALESCE(supplies_magentaToner_pctRemaining, supplies_magentaInk_pctRemaining) as 'Magenta',
    COALESCE(supplies_yellowToner_pctRemaining, supplies_yellowInk_pctRemaining) as 'Yellow'
FROM meters
ORDER BY timestamp DESC`;
            case QueryEngineReportType.SerialNumberMeterHistory:
                return `SELECT
    DATETIME(timestamp) as 'Timestamp',
    entity_name as 'Entity',
    meter_ip_address as 'IP Address',
    pageCounts_default_total as 'Total',
    pageCounts_default_totalBlack as 'Total Black',
    pageCounts_default_totalColor as 'Total Color',
    pageCounts_default_totalCopies as 'Total Copies',
    pageCounts_default_totalPrints as 'Total Prints',
    pageCounts_default_totalFaxes as 'Total Faxes',
    pageCounts_default_totalScans as 'Total Scans',
    pageCounts_default_tier1Total as 'Tier 1 Total',
    pageCounts_default_tier2Total as 'Tier 2 Total',
    pageCounts_default_tier3Total as 'Tier 3 Total',
    COALESCE(supplies_blackToner_pctRemaining, supplies_blackInk_pctRemaining) as 'Black',
    COALESCE(supplies_cyanToner_pctRemaining, supplies_cyanInk_pctRemaining) as 'Cyan',
    COALESCE(supplies_magentaToner_pctRemaining, supplies_magentaInk_pctRemaining) as 'Magenta',
    COALESCE(supplies_yellowToner_pctRemaining, supplies_yellowInk_pctRemaining) as 'Yellow'
FROM meters
ORDER BY timestamp DESC`;
            case QueryEngineReportType.EstimatedDepletionOnDemand:
                return `SELECT entity_name as 'Entity Name',
    make as Make,
    model as Model,
    serial_number as 'Serial Number',
    asset_id as 'Asset ID',
    custom_location as 'Location',
    note as 'Notes',
    ip_address as 'IP Address',
    DATETIME(latest_meter_timestamp) as 'Latest Meter Timestamp',
    average_daily_volume_total as 'Avg Daily Vol Total',
    average_daily_volume_totalColor as 'Avg Daily Vol Color',
    black_toner_current_level as 'Black Level',
    black_toner_estimated_pages_remaining as 'Black Estimated Pages Remaining',
    DATE(black_toner_estimated_depletion) as 'Black Toner Depletion',
    cyan_toner_current_level as 'Cyan Level',
    cyan_toner_estimated_pages_remaining as 'Cyan Estimated Pages Remaining',
    DATE(cyan_toner_estimated_depletion) as 'Cyan Toner Depletion',
    magenta_toner_current_level as 'Magenta Level',
    magenta_toner_estimated_pages_remaining as 'Magenta Estimated Pages Remaining',
    DATE(magenta_toner_estimated_depletion) as 'Magenta Toner Depletion',
    yellow_toner_current_level as 'Yellow Level',
    yellow_toner_estimated_pages_remaining as 'Yellow Estimated Pages Remaining',
    DATE(yellow_toner_estimated_depletion) as 'Yellow Toner Depletion'
FROM device_estimated_depletion_on_demand
WHERE DATE(black_toner_estimated_depletion) < DATE('now', '+42 days')
    OR DATE(cyan_toner_estimated_depletion) < DATE('now', '+42 days')
    OR DATE(magenta_toner_estimated_depletion) < DATE('now', '+42 days')
    OR DATE(yellow_toner_estimated_depletion) < DATE('now', '+42 days')`;
            case QueryEngineReportType.MonthlyDeviceVolume:
                return `SELECT
    make as Make,
    model as Model,
    serial_number as 'Serial Number',
    ip_address as 'IP Address',
    hostname as Hostname,
    firmware as Firmware,
    location as Location,
    year as Year,
    month as Month,
    total as Total,
    total_black as 'Total Black',
    total_color as 'Total Color',
    total_volume as 'Total Volume',
    total_black_volume as 'Total Black Volume',
    total_color_volume as 'Total Color Volume'
FROM devices
ORDER BY id, year, month_number ASC`;
            case QueryEngineReportType.Supplies:
                return `SELECT
        entity_name as 'Entity',
        device_make as 'Device Make',
        device_model as 'Device Model',
        device_serial_number as 'Device Serial Number',
        device_asset_id as 'Device Asset ID',
        name as 'Name',
        type as 'Type',
        color as 'Color',
        serial_number as 'Serial Number',
        part_number as 'Part Number',
        DATETIME(installed_date) as 'Installed Date',
        DATETIME(replaced_date) as 'Replaced Date',
        DATETIME(estimated_depletion_date) as 'Estimated Depletion Date',
        ROUND(JULIANDAY(estimated_depletion_date) - JULIANDAY(DATETIME()), 0) as 'Estimated Days Remaining',
        remaining_at_install as '% Remaining at Install',
        remaining as '% Remaining',
        pages_printed as 'Pages Printed',
        expected_yield as 'Expected Yield',
        fill_rate as 'Fill Rate',
        lost_pages as 'Lost Pages',
        actual_cost_per_page as 'Actual Cost/Page'
FROM supplies;`;
            case QueryEngineReportType.BillableDevices:
                return `SELECT
    entity_name as 'Entity',
    month as 'Month',
    year as 'Year',
    count() as 'Billable Devices'
FROM billable_devices
GROUP BY entity_id, year, month
ORDER BY entity_name, year, month_number;`;
            case QueryEngineReportType.Entity:
                return `SELECT 
    name as 'Name',
    DATETIME(created_date) as 'Created'
FROM entities`;
            default:
                return `SELECT
    DATETIME(a.timestamp) as 'Timestamp',
    a.entity_name as 'Entity',
    a.device_make || ' ' || a.device_model as 'Device',
    a.device_serial_number as 'Device Serial Number',
    a.description as 'Description',
    a.supply as 'Supply',
    a.alert_type as 'Type',
    m.pageCounts_default_total as 'Total',
    m.pageCounts_default_totalBlack as 'Total Black',
    m.pageCounts_default_totalColor as 'Total Color',
    a.acknowledged as 'Acknowledged',
    a.acknowledged_user as 'Acknowledged By',
    DATETIME(a.acknowledged_timestamp) as 'Acknowledged Timestamp'
FROM alerts a
INNER JOIN meters m ON m.meter_id = a.meter_id`;
        }
    }
}
